//import {decode, encode} from 'base-64'
import axios from "axios";
 


let BASE_URL_2 = "https://dklist.com/me_api/public";

const api = axios.create({
  baseURL: BASE_URL_2,
});

 

export const get = async (url) => {
  const response = await api.get(url);
  return response;
};

export const post = async (url, data, config = false) => {
  config = config || { headers: { "Content-Type": "multipart/form-data" } };
  const response = api.post(url, data, config);
  return response;
};

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status == 401) {
      console.warn("Oturum Süresi Bitti!");
      
      return Promise.resolve({
        status: 401,
        data: error?.response?.data ?? error?.response?.message,
      });
    } else {
      return Promise.resolve({
        status: error?.response?.status,
        data: error?.response?.data ?? error?.response?.message,
      });
    }
  }
);

// api.interceptors.request.use(function (config) {
//   if (basicControl(Store?.getState()?.tokenReducer?.token)) {
//     config.headers.Authorization =
//       "Bearer " + Store?.getState()?.tokenReducer?.token;
//   }
//   return config;
// });
