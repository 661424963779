import React, {useContext, createContext } from "react";
import Cookies from 'js-cookie';

const UserAuthContext = createContext()
const useUserAuth = () => {return useContext(UserAuthContext)}

const UserAuthContextProvider = ({children}) => {

    //let now_date  = new Date();
    //const expires = new Date(now_date.getTime() + 2 * 60 * 60 * 1000);
    const expires = 7

    // const [ user, setUser ] = React.useState({
    //     username  : localStorage.getItem('username') ? localStorage.getItem('username')  : null,
    //     token     : localStorage.getItem('token')    ? localStorage.getItem('token')     : null,
    //     id        : localStorage.getItem('id')       ? localStorage.getItem('id')        : null,
    //     userType  : localStorage.getItem('userType') ? localStorage.getItem('userType')  : null,
    //     publisher : localStorage.getItem('publisher') ? localStorage.getItem('publisher') : null,
    //     img       : localStorage.getItem('img')      ? (localStorage.getItem('img') !== 'null' ? localStorage.getItem('img') : null) : null,
    // });

    const [ user, setUser ] = React.useState({
        username  : Cookies.get('username') ?   Cookies.get('username')  : null,
        token     : Cookies.get('token')    ?   Cookies.get('token')     : null,
        id        : Cookies.get('id')       ?   Cookies.get('id')        : null,
        userType  : Cookies.get('userType') ?   Cookies.get('userType')  : null,
        publisher : Cookies.get('publisher') ?  Cookies.get('publisher') : null,
        img       : Cookies.get('img')      ? ( Cookies.get('img') !== 'null' ? Cookies.get('img') : null) : null,
    });
    
    const [userLogin, setUserLogin] = React.useState(false);

    const data = { user, setUser, userLogin, setUserLogin }

    React.useEffect(() => {
        if ( user.username && user.id && user.token ) {

            // localStorage.setItem('username',user.username);
            // localStorage.setItem('token',user.token);
            // localStorage.setItem('img',user.img);
            // localStorage.setItem('id',user.id);
            // localStorage.setItem('userType',user.userType);
            // localStorage.setItem('publisher', user.publisher);

            Cookies.set('username',user.username,{expires});
            Cookies.set('token',user.token,{expires});
            Cookies.set('img',user.img,{expires});
            Cookies.set('id',user.id,{expires});
            Cookies.set('userType',user.userType,{expires});
            Cookies.set('publisher', user.publisher,{expires});
            setUserLogin(true);
        }
        else{
            setUserLogin(false);
        }
    }, [user]);

    
    return(
        <UserAuthContext.Provider value={ data }>
            {children}
        </UserAuthContext.Provider>
    )
}
export{
    useUserAuth,
    UserAuthContextProvider
}