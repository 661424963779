import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import throwNotification from '../../../GeneralFunctions/throwNotification';
import debounce from "lodash.debounce"; 
import apiRequest from '../../../services';
import { useUserAuth } from '../../../Context/UserAuthContext';
import {   Button,
    Cascader,
    Checkbox,
    ColorPicker,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Slider,
    Switch,
    TreeSelect,
    Upload } from 'antd';
import { get, post } from '../../../api/request';



const Blog = () => {

    const {user} = useUserAuth();
    const [ query, setQuery ] = React.useState({
        pagePerSize:10,
        page:1,
        search:'',
        sortBy:'approved',
        orderBy:'ASC'
    });

    const { RangePicker } = DatePicker;
const { TextArea } = Input;

    const initialMeta = { page:1, firstPage:1, lastPage:1, pagePerSize:10, filteredCount:0, orderBy:'ASC', sortBy:'id', viewCount:0 };
    const [ data, setData ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(false);
    const [ loading2, setLoading2 ] = React.useState(false);
    const [adsFormat,setAdsFormat] = React.useState(false)

    const [textareaValue,setTextareaValue] = React.useState('')
    const [inputValue,setInputValue] = React.useState('')


    const [textareaValue2,setTextareaValue2] = React.useState('')
    const [inputValue2,setInputValue2] = React.useState('')

    const modalCodeSubmit = async () => {
        setLoading(true);
        try {
            const data = new FormData();
            data.append('textarea_value', textareaValue);
            data.append('input_value', inputValue);
            data.append('modal_ads', 1);
                
            const response = await post(`ads-add.php`,data);
            if( response.status !== 200){
                throwNotification({
                    type:'error',
                    message:'Bir hata oluştu',
                    description: response?.data?.message,
                    duration:3
                });
            }
            throwNotification({
                type:'success',
                message:'Başarılı',
                description: response?.data?.message,
                duration:3
            });
          
          } catch (error) {
              alert(error.message);
          }
 
 
        setLoading(false);
    }


    const bannerCodeSubmit = async () => {
        setLoading2(true);
        try {
            const data = new FormData();
            data.append('textarea_value', textareaValue2);
            data.append('input_value', inputValue2);
            data.append('banner_ads', 1);
                
            const response = await post(`ads-add.php`,data);
            if( response.status !== 200){
                throwNotification({
                    type:'error',
                    message:'Bir hata oluştu',
                    description: response?.data?.message,
                    duration:3
                });
            }
            throwNotification({
                type:'success',
                message:'Başarılı',
                description: response?.data?.message,
                duration:3
            });
          
          } catch (error) {
              alert(error.message);
          }
 
 
        setLoading2(false);
    }


    React.useEffect(()=>{

        ( async ()=>{

            let get_data = await get("ads-get.php?format=true");

            if( get_data.status === 200  ){
                setData(get_data?.data?.data);
                if( get_data?.data?.data[0]?.iframe != '' && get_data?.data?.data[0]?.iframe != undefined  ){
                    setAdsFormat(false)
                    setTextareaValue(get_data?.data?.data[0]?.iframe)
                }else{
                    setAdsFormat(true)
                    setInputValue(get_data?.data?.data[0]?.link)
                    setTextareaValue(get_data?.data?.data[0]?.image)
                }

                if( get_data?.data?.data[1] ){
                    setInputValue2(get_data?.data?.data[1]?.link)
                    setTextareaValue2(get_data?.data?.data[1]?.image)
                }
            }
             

        })();

    },[])
 

    return (
        <motion.div initial={{ opacity:0 }} animate={{ opacity:1 }}>
            
    
            
            <>
      
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal" 
        style={{
          maxWidth: 600,
        }}
      > 
        
        <h3>Modal Reklam Alanı</h3><br />
        {adsFormat && <>
        <span>Reklam linki</span>
            <Form.Item>
              <Input allowClear value={inputValue} onChange={(e)=> setInputValue(e.target.value)} />
            </Form.Item>
        </>}

        <span>Reklam Kodu</span>
        
        <Form.Item>
          <TextArea rows={4} allowClear onChange={(e) => {setTextareaValue(e.target.value) }} value={textareaValue} placeholder={adsFormat? 'Bu alana resim linkini ekleyebilirsiniz' :'Bu alana youtube video kodu ekleyebilirsiniz'} />
        </Form.Item>


        <span>Modal Formatı  <strong>({adsFormat?'Resim':'Youtube'})</strong>  </span>
        <Form.Item valuePropName="checked">
          <Switch onChange={(val)=> setAdsFormat(val) } checked={adsFormat} />
        </Form.Item>
        <Button type="primary" loading={loading} onClick={()=>modalCodeSubmit()}>Kaydet</Button>

      </Form>


<br />
<hr />
<br /><br />

    <h3>Banner Reklam Alanı</h3><br />
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal" 
        style={{
          maxWidth: 600,
        }}
      > 
        
       
    <span>Reklam linki</span>
    <Form.Item>
        <Input allowClear value={inputValue2} onChange={(e)=> setInputValue2(e.target.value)} />
    </Form.Item>
    

        <span>Reklam Kodu</span>
        
        <Form.Item>
          <TextArea rows={4} value={textareaValue2} allowClear onChange={(e) => {setTextareaValue2(e.target.value) }} placeholder={'Bu alana resim linkini ekleyebilirsiniz'} />
        </Form.Item>
 
        <Button type="primary" loading={loading2} onClick={()=>bannerCodeSubmit()}>Kaydet</Button>

      </Form>
    </>

        </motion.div>
    );
}

export default Blog;