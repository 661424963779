import React, { useEffect } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCakeCandles, faUserGraduate, faLocationDot, faCalendarDays,faUser  } from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleOutlined } from "@ant-design/icons";

import Cookies from 'js-cookie';

import { useProfile } from "../../../Context/UserProfileContext";
import { useTranslation } from "react-i18next";
import throwNotification from "../../../GeneralFunctions/throwNotification";
import { useUserAuth } from "../../../Context/UserAuthContext";
import apiRequest from "../../../services";
const UserBiyoComponent = () => {
    const { profileData } = useProfile(); 
    const { user } = useUserAuth();
    const { t } = useTranslation();
    const [ showNotice, setShowNotice ] = React.useState(true);

    const expires = 7
 
 
    const notice = async () => {

       let request =  await apiRequest({endpoint:'/notice', body:JSON.stringify({user_id:profileData?.userID,notice_text:"Bu profilin incelenmesini istiyorum", type:'comment'}), headers:{Authorization:user.token}, method:'POST'});
         
        if ( request.error || !request.responseData || !request.responseData.status ) {
            throwNotification({
                type:'error',
                message:"Bir hata oluştu tekrar dene!",
                duration:3
            }); 
        }else{
            throwNotification({
            description: "Profil Şikayet Edildi",
            duration:2,
            })
            Cookies.set('noetice_profil_'+profileData?.userID,profileData?.userID,{expires})
            setShowNotice(false);
        }
    }
    //console.log(profileData,"profileData");

    React.useEffect(()=>{
        let noetice_ = Cookies.get('noetice_profil_'+profileData?.userID)
        setShowNotice(user.id != profileData?.userID && noetice_ == undefined ? true : false)
    },[profileData])
    
    return(
        <div className="userBiyoContainer">
            <span>{'@'+profileData.username}</span>
            {
                profileData.name && <span>{ profileData.surname ? profileData.name + ' ' + profileData.surname : profileData.name } </span>
            }
            {
                profileData.edu &&<span><FontAwesomeIcon style={{marginRight:10}} icon={faUserGraduate}/>{profileData.edu}</span>
            }
            {
                (profileData.birthDate || profileData.birthPlace) && <span><FontAwesomeIcon style={{marginRight:10}} icon={faCakeCandles}/> { profileData.birthDate && profileData.birthPlace ? profileData.birthDate + ' ' +  profileData.birthPlace : profileData.birthDate ? profileData.birthDate : profileData.birthPlace} </span>
            }
            {
                profileData.livingCity && <span><FontAwesomeIcon style={{marginRight:10}} icon={faLocationDot}/> {profileData.livingCity}</span>
            }
            <span><FontAwesomeIcon style={{marginRight:10}} icon={faCalendarDays}/> {profileData.createdDate+' '+t('tarihindekatildi')} </span>
            <span><FontAwesomeIcon style={{marginRight:10}} icon={faUser}/> {profileData.userType} </span>
            {showNotice && <span  onClick={notice} style={{cursor:"pointer",color:"darkred"}}><ExclamationCircleOutlined className="commentLeftContainerBodyIcon" style={{color:'red'}}/> <strong>Şikayet Et</strong>   </span>}
            <span>{profileData.biyo}</span>
        </div>
    )
}

export default UserBiyoComponent;