import { Button, Form, Popconfirm } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useTranslation } from 'react-i18next';
import { useProfile } from "../../../Context/UserProfileContext";
import apiRequest from "../../../services";
import { useUserAuth } from "../../../Context/UserAuthContext";
import throwNotification from "../../../GeneralFunctions/throwNotification";



const ActionsComponent = () => {

    const { t } = useTranslation();
    const [form] = useForm();
    const { profileData, setProfileData } = useProfile(); 
    const { user } = useUserAuth();

    const onFinish = async (values) => {

        const message = values.message;

        const request = await apiRequest({endpoint:'/message/send', headers:{Authorization:user.token}, method:'POST', body:JSON.stringify({message:message, receiverID:profileData.userID})});
        
        if ( request.error || !request.responseData || !request.responseData.status ) {
            throwNotification({
                type:'error',
                message:t('mesaj_gonderilirken_bir_hata_olustu'),
                duration:3
            });
            console.error('Alınan hata : ', request.errorMessage);
        }
        else{
            form.resetFields();
        }
    }

    return(
        <div className='actionsContainer'>

            <Popconfirm
                icon={null}
                okText={t('kapat')}
                cancelText={t('iptal')}
                onCancel={() => { form.resetFields(); }}
                description = { 
                    <Form form={form} onFinish={onFinish}>
                        <Form.Item name={'message'} rules={[{required:true, message:t('gerekli')}]}>
                            <TextArea maxLength={500} showCount={true}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"  >{t('gonder')}</Button>
                        </Form.Item>
                    </Form>
                }
            >
                <span >{t('mesajyaz')}</span>
            </Popconfirm>

            <span onClick={ async () => {
                const oldProfile = profileData;
                if (profileData.currentUserIsFallow) {
                    setProfileData({
                        ...profileData, 
                        currentUserIsFallow:!profileData.currentUserIsFallow, 
                        currentUserToken:user.token, 
                        followers:profileData.followers.filter( item => item.id !== user.id )
                    })
                }
                else{
                    setProfileData({
                        ...profileData, 
                        currentUserIsFallow:!profileData.currentUserIsFallow, 
                        currentUserToken:user.token, 
                        followers:[...profileData.followers, {id:user.id, username:user.username, image:user.img}]
                    })
                }
                const request = await apiRequest({endpoint:'/switch-follow/'+profileData.userID, headers:{Authorization:user.token}});
                if (request.error || request.responseData.status === false) {
                    setProfileData({...oldProfile});
                }
            }} >{profileData.currentUserIsFallow ? t('takipten_cik') : t('takipet')}</span>
        </div>
    )
}
export default ActionsComponent;