import {} from "@ant-design/icons"

export function linkify(text) {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.split(urlPattern).map((part, index) =>
        urlPattern.test(part) ? (
            <> <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{color: "#ffffff",marginRight: "5px"}}>{part}</a> </>
        ) : (
            part
        )
    );
}