import { Button, Form, Input, Modal, Popover, Progress } from "antd";
import React from "react";
import { useProfile } from "../../../Context/UserProfileContext";
import { useTranslation } from "react-i18next";
import throwNotification from "../../../GeneralFunctions/throwNotification";
import { useUserAuth } from "../../../Context/UserAuthContext";
import apiRequest from "../../../services";


const UserReadStatusesComponent = () => {

    const { profileData, setProfileData } = useProfile();
    const { t }                           = useTranslation();
    const [ show, setShow ]               = React.useState(false);
    const { user }                        = useUserAuth();

    return(
        <div className="userReadStatusesComponent">

            {   
                Number(user.id) === Number(profileData.userID) ?
                <Popover
                    content={
                        <Form onFinish={async (values) => {
                            if (values.newReadTarget < 1) {
                                throwNotification({
                                    message:t('okumahedefienaz1olabilir'),
                                    type:'warning',
                                    duration:3
                                });
                                return;
                            }
                            const body = JSON.stringify({count:values.newReadTarget})
                            const request = await apiRequest({headers:{Authorization:user.token}, endpoint:'/set-read-purpose', method:'POST', body:body});

                            if ( !(request.error || !request.responseData || !request.responseData.status) ) {
                                setProfileData({...profileData, read:{...profileData.read, readTarget:values.newReadTarget}, currentUserToken:user.token})
                            }
                        }}>
                            <Form.Item name={'newReadTarget'} rules={[{required:true, message:t('gerekli')}]}>
                                <Input type="number" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"  >{t('gonder')}</Button>
                            </Form.Item>
                        </Form> 
                    }
                >
                    <p style={{margin:'10px 0', cursor:'pointer', fontWeight:600}}>{t('okumahedefi')+' : '+(profileData.read && profileData.read.readTarget ? profileData.read.readTarget : 1 )}</p>
                </Popover>
                :
                <p style={{margin:'10px 0', fontWeight:600}}>{t('okumahedefi')+' : '+(profileData.read && profileData.read.readTarget ? profileData.read.readTarget : 1 )}</p>
            }

            <span onClick={() => { profileData.read.oldReadedList.length>0 && setShow(true); }}>
                <Progress
                    percent={ profileData.read && profileData.read.readTarget && profileData.read.readTarget > 0 ? profileData.read.readdedList.length/( profileData.read.readTarget ) * 100 : 0} // TODO değişiklik1
                    trailColor="rgba(0,0,0,.4)"
                    strokeColor={'#52c41a'}
                    type="circle"
                    format={(percent) => {return <span>{percent.toFixed(2)+'%'}</span>;}}
                    style={{cursor:profileData.read.oldReadedList.length >0 ? 'pointer' : 'default'}}
                />
            </span>

            <p style={{margin:'10px 0', fontWeight:600}}>{t('okunan')+' : '+profileData.read.readdedList.length}</p>
            <Modal 
                open={show}
                footer={null}
                closeIcon={null}
                onCancel={() => {setShow(false)}}
                bodyStyle={{display:'flex', justifyContent:'center', flexWrap:'wrap'}}
            >
                {
                    profileData.read.oldReadedList.length > 0 && profileData.read.oldReadedList.map( item => {
                        return(
                            <div key={item.id} style={{width:140, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginBottom:20}}>
                                <div>{item.year + ' ' + t('okumahedefi')}</div>
                                <Progress
                                    percent={(item.readCount/item.targetCount)*100}
                                    trailColor="rgba(0,0,0,.4)"
                                    strokeColor={'#52c41a'}
                                    type="circle"
                                    format={(percent) => {return <span>{percent.toFixed(2)+'%'}</span>;}}
                                />
                            </div>
                        )
                    })
                }
            </Modal>
        </div>
    );
}

export default UserReadStatusesComponent;