import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { get } from "../../api/request";
import Cookies from 'js-cookie';

const Kapak = () => {
  const [modal1Open, setModal1Open] = React.useState(false);
  const [banner, setBanner] = React.useState(null);
  const [modal, setModal] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      let get_data = await get("ads-get.php?format=true");

      if (get_data.status === 200) {
        if (get_data?.data?.data[0]) {
          setModal(get_data?.data?.data[0]);
          if( get_data?.data?.data[0]?.image != '' ){
            setModal1Open(true)
          }else if(get_data?.data?.data[0]?.iframe != ''){
            setModal1Open(true)
          }else{
            setModal1Open(false)
          }

        }

        if (get_data?.data?.data[1]) {
          setBanner(get_data?.data?.data[1]);
        }
      }
    })();
  }, []);

  const { t } = useTranslation();

  const onClose = ()=>{
    Cookies.set("modal",1,{expires:1})
    setModal1Open(false)
  }

  return (
    <>
      { banner?.image != '' && (
        <div className="kapakContainer">
          <a href={banner?.link}>
            <img src={banner?.image} />
          </a>
        </div>
      )}
 {Cookies.get("modal") == undefined && <Modal
        style={{
          top: 20,
        }}
        centered
        open={modal1Open}
        width="auto"
        closable={true}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => onClose()}
      >
        {modal?.image ? (
          <a href={modal?.link}>
            <img src={modal?.image} />
          </a>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: modal?.iframe,
            }}
          ></div>
        )}
      </Modal> }
    </>
  );
};

export default Kapak;
