import './style.css';
import { useProfile } from '../../Context/UserProfileContext';
import React from 'react';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Drawer, Modal,Tabs, Select, Spin, Tooltip } from 'antd';
import ImageComponent from './components/ImageComponent';
import { useUserAuth } from '../../Context/UserAuthContext';
import ActionsComponent from './components/ActionsComponent';
import UserBiyoComponent from './components/UserBiyoComponent';
import UserReadStatusesComponent from './components/UserReadStatusesComponent';
import UserFollowContainer from './components/UserFollowContainer/UserFollowContainer';
import LikedContainer from './components/LikedContainer/LikedContainer';
import { useScreenSize } from '../../Context/ResponsiveContext';
import BadgeContainer from './components/BadgeContainer';
import SettingsModal from './components/SettingsModal';
import { useTranslation } from 'react-i18next';
import BookListComponent from './components/BookListComponent/BookListComponent';
import apiRequest from '../../services';
import throwNotification from '../../GeneralFunctions/throwNotification';
import ButtonComponent from '../ButtonComponent';
import BlogContainer from './components/BlogsContainer';

const UserProfileDrawer = () => {

    const [ modalData, setModalData ] = React.useState({tabkey:1, show:false});
    const [ modalData2, setModalData2 ] = React.useState({tabkey:1, show:false});
    const { profileData, setProfileData } = useProfile(); 
    const { user } = useUserAuth();
    const {screenSize} = useScreenSize();
    const [ settingsModalShow, setSettingsModalShow ] = React.useState(false);
    const { t } = useTranslation();

    const [show, setShow] = React.useState(false);
    const [ books, setBooks ] = React.useState([]);
    const [ bookLoading, setBookLoading ] = React.useState(false);
    const [ selectedBook, setSelectedBook ] = React.useState(-1);


    const items = [
        {
            key: 1,
            label: t('kutuphane'),
            children: <BookListComponent data={profileData.library} title={t('kutuphane')} />,
        },
        {
            key: 2,
            label: t('suan_okuduklari'),
            children: <BookListComponent data={profileData.read.currentlyReadingList} title={t('suan_okuduklari')}/>,
        },
        {
            key: 3,
            label: t('okuyacaklari'),
            children: <BookListComponent data={profileData.read.futureReadingList} title={t('okuyacaklari')}/>,
        },
        {
            key: 4,
            label: t('okuduklari'),
            children: <BookListComponent data={profileData.read.readdedList} title={t('okuduklari')}/>,
        },
    ];

    const items2 = [
        {
            key: 1,
            label: t('blog'),
            children: <div className="blogProfileContainer"> <BlogContainer/> </div>,
        }
    ];

    return (
        <Drawer  width={300} placement="right" closable={screenSize<400} bodyStyle={{padding:0, margin:0}} onClose={() => {setProfileData({...profileData, show:false, currentUserToken:user.token})}} open={profileData.show}>
            <div className='profileDrawerContainer'>
                {
                    ( Number(user.id) === Number(profileData.userID) ) &&  
                    <Tooltip title={t('ayarlar')}>
                        <SettingOutlined className='settingsIcon' onClick={() => {setSettingsModalShow(true)}}/>
                    </Tooltip>
                }
                {
                    false
                    ?
                    <Spin size='large' style={{margin:'60% auto'}}/>
                    :
                    <>
                        <ImageComponent image={profileData.image} />
                        {
                            user.token && Number(user.id)!==Number(profileData.userID) && <ActionsComponent currentUserIsFallow={ profileData.currentUserIsFallow } />
                        }
                        <UserBiyoComponent/>
                        <BadgeContainer/>
                        { profileData.read && <UserReadStatusesComponent/> }

                        { profileData.followers && profileData.follow && <UserFollowContainer/>}
                        <LikedContainer/>
                        {  <> <BookListComponent data={profileData.library} header = { () => { return <span> {t('kutuphane')} {
                            Number(user.id) === Number(profileData.userID) && <PlusOutlined onClick={() => { setShow(true) }} className='addBookToLibraryButton'/> 
                        } </span> } } count={2} /> {profileData.library.length> 2 && <a href="javascript:void(0)" onClick={()=>{ setModalData({show:true, tabkey:1})}}>Hepsini Gör</a>} </>}
                        { profileData && profileData.read  && profileData.read.currentlyReadingList && profileData.read.currentlyReadingList.length>0 && <><BookListComponent data={profileData.read.currentlyReadingList} title={t('suan_okuduklari')}  count={2}/> {profileData.read.currentlyReadingList.length> 2 && <a href="javascript:void(0)" onClick={()=>{ setModalData({show:true, tabkey:1})}}>Hepsini Gör</a>} </> }
                        { profileData && profileData.read  && profileData.read.futureReadingList && profileData.read.futureReadingList.length > 0 && <><BookListComponent data={profileData.read.futureReadingList} title={t('okuyacaklari')}  count={2}/> {profileData.read.futureReadingList.length > 2 && <a href="javascript:void(0)" onClick={()=>{ setModalData({show:true, tabkey:1})}}>Hepsini Gör</a>} </>}
                        { profileData && profileData.read  && profileData.read.readdedList && profileData.read.readdedList.length > 0 && <><BookListComponent data={profileData.read.readdedList} title={t('okuduklari')}  count={2}/> {profileData.read.readdedList.length > 2 && <a href="javascript:void(0)" onClick={()=>{ setModalData({show:true, tabkey:1})}}>Hepsini Gör</a>} </>}
                        { profileData && profileData.blogs && profileData.blogs.length>0 && <div className="blogProfileContainer"> <BlogContainer count={3}/>  {profileData.blogs.length > 3 && <a href="javascript:void(0)" onClick={()=>{ setModalData2({show:true, tabkey:1})}}>Hepsini Gör</a>} </div>}
                    </>
                }
                <SettingsModal setShow={setSettingsModalShow} show={settingsModalShow}/>
                <Modal
                    open={show}
                    onCancel={() => {setShow(false)}}
                    onOk={()=>{setShow(false)}}
                    footer={null}
                >
                    <span>{t('kitap_sec')}</span>
                    <Select
                        style={{width:'100%', margin:'20px 0'}}
                        showSearch
                        filterOption ={ (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        onChange={ (value) => {setSelectedBook(value)}}
                        onClick={async () => {

                            setBookLoading(true);

                            const request = await apiRequest({endpoint:'/get-all-books-for-library', headers:{Authorization:user.token}});

                            if (request.error || request.responseData.status === false) {
                                throwNotification({
                                    message:t('bir_hata_olustu'),
                                    type:'error',
                                    duration:4
                                });
                            }
                            else{
                                setBooks(request.responseData.response)
                            }
                            setBookLoading(false)
                        }}
                        loading = {bookLoading}
                        options={books}
                    />

                    <ButtonComponent 
                        disabled = {selectedBook === -1}
                        onClick = { async() => {

                            if (selectedBook === -1 || Number(profileData.userID) !== Number(user.id)) {
                                return;
                            }

                            // TODO buradaki istek switch tarzı çalışır, ileride kütüphanedek kitap kaldırmak istersem ilgili yere bu butonu koyabilirim
                            const request = await apiRequest({endpoint:'/book-switch-for-profile/'+selectedBook, headers:{Authorization:user.token}});

                            if (request.error || request.responseData.status === false) {
                                throwNotification({
                                    message:t('bir_hata_olustu'),
                                    type:'error',
                                    duration:4
                                });
                            }
                            else if(request.responseData.response){
                                setProfileData({...profileData, library:[...profileData.library, request.responseData.response]})
                            }
                            else{
                                setProfileData({...profileData, library:profileData.library.filter( book => book.id !== selectedBook )})
                            }
                        }}
                    >
                        {t('kaydet')}
                    </ButtonComponent>
                    

                </Modal>

 
                    <Modal
                        open={modalData.show}
                        bodyStyle={{padding:0}}
                        width={screenSize > 600 ? '40%' : '100%'}
                        footer={null}
                        onCancel={() => {setModalData({...modalData, show:false})}}
                    >
                    <Tabs items={items} activeKey={modalData.tabkey} style={{margin:0, padding:0}} onChange={key => {setModalData({...modalData, tabkey:key})}} />
                    </Modal>


                    {/* blog */}
                    <Modal
                        open={modalData2.show}
                        bodyStyle={{padding:0}}
                        width={screenSize > 600 ? '40%' : '100%'}
                        footer={null}
                        onCancel={() => {setModalData2({...modalData2, show:false})}}
                    >
                    <Tabs items={items2} activeKey={modalData2.tabkey} style={{margin:0, padding:0}} onChange={key => {setModalData2({...modalData2, tabkey:key})}} />
                    </Modal>

            </div>
        </Drawer>
    );
};
export default UserProfileDrawer;