import React from 'react';
import { motion } from 'framer-motion';


const IletiAyarlari = () => {



    return (
        <motion.div initial={{ opacity:0 }} animate={{ opacity:1 }}>Ileti Ayarlari Sayfası</motion.div>
    );
}

export default IletiAyarlari;